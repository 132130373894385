// config.js

const config = {
    development: {
      endpoint: "http://localhost:8081"
    },
    production: {
      endpoint: "http://didit.ai:8081"
    }
  };
  
  // Use REACT_APP_ENV if set, otherwise default to 'development'
  const environment = process.env.REACT_APP_ENV || 'production';
  
  const selectedConfig = config[environment];
  
  console.log(`Current environment: ${environment}`);
  console.log(`Using endpoint: ${selectedConfig.endpoint}`);
  
  export const endpoint = selectedConfig.endpoint;
  export const currentEnvironment = environment;
