import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Container, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import axios from 'axios';
import AppContent from './AppContent';

import { endpoint } from './config';

axios.defaults.withCredentials = true;

function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;