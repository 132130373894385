import React, { Component } from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import { Card, Header, Form, Input, Icon, Button, List, Checkbox, Image } from "semantic-ui-react";
import styled from 'styled-components';
import './DiditList.css'; // Adjust the path as needed

import { endpoint, currentEnvironment } from './config';

const sizeOptions = [
    { key: '1', text: 'Small', value: 1 },
    { key: '2', text: 'Medium', value: 2 },
    { key: '3', text: 'Large', value: 3 }
];

// Define a helper function to check if the due date is today or earlier
function isDueDateTodayOrEarlier(dueDate) {
    if (!dueDate) return false; // Handle cases where dueDate is null or undefined

    const today = new Date();
    const due = new Date(dueDate);

    // Compare dates without comparing time
    today.setHours(0, 0, 0, 0);
    due.setHours(0, 0, 0, 0);

    return due < today;
}

function formatFormDate(inputDate) {
    // Step 1: Parse the input date string into a Date object
    const dateObject = new Date(inputDate);

    // Step 2: Format the date to YYYY-MM-DD
    const formattedDate = dateObject.toISOString().split('T')[0];

    return formattedDate;
}

function formatDueDate(dueDate) {
    if (!dueDate) return null;

    const date = new Date(dueDate);

    // Check if date is valid and later than year 0001
    if (isNaN(date.getTime()) || date.getFullYear() <= 10) {
        return null; // Invalid date
    }

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC' // Set timeZone option to UTC
    };

    const utcDateFormatted = date.toLocaleDateString('en-US', options);

    return utcDateFormatted
}

function normalizeUrl(url) {
    if (!url) return '';

    // Ensure the URL has a protocol (http:// or https://)
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        // Prepend http:// (or https:// based on your needs)
        return `http://${url}`;
    }

    return url;
}

function safeUrl(url) {
    // Validate and sanitize URL using a library or custom validation
    // Here we're just doing basic validation for example purposes
    if (!url) return null; // Handle empty URLs

    // Basic URL validation (adjust as per your application's requirements)

    // TODO: Ensure that the URL is safe

    // const pattern = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
    // if (!pattern.test(url)) return null; // Invalid URL format

    return url;
}

const RoundedCard = styled(Card)`
  &&& {
    border-radius: 20px !important;
    overflow: hidden; // This ensures the content inside the card respects the border radius
  }
`;

const CompactList = styled(List)`
  .item {
    padding: 0px 0px 0px 0px;
    margin-bottom: 8px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    transition: box-shadow 0.2s;

    > div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      padding: 8px 10px;
    }

    .task-label {
      flex: 1;
      min-width: 0; // This allows the flex item to shrink below its content size
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .due-date {
      white-space: nowrap;
      margin-left: 10px;
      margin-right: 5px;
      font-size: 0.9em;
    }
      
    &:hover {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

  }
`;

const CompactInput = styled(Input)`
  margin-bottom: 8px;

  input {
    border-radius: 20px !important;
    background-color: white !important;
    border: 1px solid #e0e0e0 !important;
    padding: 8px 10px !important;
    height: 40px !important;
  }

`;

const CompactCheckbox = styled(Checkbox)`
    &&& {
        align-items: center;
        width: 100%;
        margin-right: 10px;
  
        label {
            width: 100%;
            font-size: 1em;
            text-decoration: ${props => props.checked ? 'line-through' : 'none'};
            color: ${props => props.checked ? '#888' : 'inherit'};
        }

        input[type="checkbox"] {
            margin-right: 10px;
            cursor: pointer;
        }
    }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 5px;
  
  > * {
    margin-left: 8px;
    cursor: pointer;
  }
`;

class DiditList extends Component {
    static propTypes = {
        user: PropTypes.shape({
            Permissions: PropTypes.arrayOf(PropTypes.string)
        }),
        isCompactMode: PropTypes.bool.isRequired
    };

    static defaultProps = {
        user: { Permissions: [] }
    };

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            parentTask: null,
            editTask: null,
            formData: {},
            currentParentId: null,
            newTaskInput: '',
            isLoading: true,
            error: null
        };
    }

    componentDidMount() {
        this.getTask();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentParams = new URLSearchParams(window.location.search);
        const currentParent = currentParams.get('p') || currentParams.get('parent');

        if (currentParent !== this.state.currentParentId) {
            this.getTask();
        }
    }

    hasPermission = (permission) => {
        const { user } = this.props;
        return user && user.Permissions && user.Permissions.includes(permission);
    };

    getTask = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const parent = queryParams.get('p') || queryParams.get('parent');
    
        if (parent) {
            this.setState({ currentParentId: parent, isLoading: true, error: null }, () => {
                this.fetchParentTask(parent);
            });
        } else {
            this.setState({ isLoading: false, parentTask: null, items: [] });
        }
    };

    fetchParentTask = (parentId) => {
        axios.post(
            `${endpoint}/api/getTask`,
            { id: parentId },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((parentRes) => {
            this.setState({ parentTask: parentRes.data }, () => {
                this.fetchChildTasks(parentId);
            });
        })
        .catch((error) => {
            console.error("Error fetching parent task:", error);
            this.setState({ error: "Failed to fetch parent task", isLoading: false });
        });
    };

    fetchChildTasks = (parent) => {
        axios.post(
            `${endpoint}/api/getChildTasks`,
            { parent },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then((res) => {
            this.setState({
                items: res.data.Tasks || [],
                isLoading: false
            });
        }).catch((error) => {
            console.error("Error fetching child tasks:", error);
            this.setState({ error: "Failed to fetch child tasks", isLoading: false });
        });
    };
    
    addChildTask = (parent, label) => {
        if (!label || !label.trim()) {
            console.log("Task label is empty. Not adding the task.");
            return;
        }

        axios.post(
            `${endpoint}/api/createChildTask`,
            { parent, label: label.trim() },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => {
            this.fetchChildTasks(parent);
            this.setState({ newTaskInput: '' });
        })
        .catch((error) => {
            console.error("Error creating child task:", error);
        });
    };

    updateTaskStatus = (uuid, status) => {
        axios.post(
            `${endpoint}/api/updateTaskStatus`,
            { id: uuid, status: status },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => {
            console.log(res);
            this.fetchChildTasks(this.state.currentParentId);
        })
        .catch((error) => {
            console.error("Error updating task status:", error);
        });
    };

    handleEditClick = (item) => {
        const dueDate = new Date(item.due);
        const isValidDate = !isNaN(dueDate.getTime()) && dueDate.getFullYear() > 1;
        
        this.setState({
            editTask: item.uuid,
            formData: {
                label: item.label,
                content: item.content,
                url: item.url,
                due: isValidDate ? formatFormDate(item.due) : '',
                size: item.size
            }
        });
    };

    handleFormChange = (e, { name, value }) => {
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleFormSubmit = () => {
        const { editTask, formData, currentParentId } = this.state;
        const updatedTask = {
            uuid: editTask,
            ...formData,
            due: formData.due ? new Date(formData.due).toISOString() : null,
        };
    
        axios.post(
            `${endpoint}/api/updateTask`,
            updatedTask,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then(() => {
            this.setState({ editTask: null, formData: {} });
            this.fetchChildTasks(currentParentId);
        }).catch(error => {
            console.error("There was an error updating the task!", error);
        });
    };

    handleNewTaskInputChange = (e) => {
        this.setState({ newTaskInput: e.target.value });
    };

    handleNewTaskSubmit = (e) => {
        e.preventDefault();
        const { newTaskInput, parentTask } = this.state;
        if (newTaskInput.trim() && parentTask) {
            this.addChildTask(parentTask.uuid, newTaskInput);
        }
    };

    toggleMode = () => {
        this.setState(prevState => ({ isCompactMode: !prevState.isCompactMode }));
    }

    renderDetailView() {
        const { items, parentTask } = this.state;
        const canEditChildTask = this.hasPermission('edit_child_task');
        const canSplitChildTask = this.hasPermission('ai_split_child_task');

        return (
            <div className="card-group" style={{padding: '15px 5px'}}>
                <Card.Group stackable doubling>
                    {parentTask && canEditChildTask && (
                        <RoundedCard key="new-input-card" raised style={{minWidth: '300px'}}>
                            <Card.Content>
                                <Card.Header>
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        const inputValue = e.target[0].value.trim();
                                        if(inputValue) {                                        
                                            this.addChildTask(parentTask.uuid, inputValue);
                                            e.target[0].value = '';
                                        }
                                    }}>
                                        <Input
                                            type="text"
                                            name="task"
                                            fluid
                                            placeholder="Create Task"
                                        />
                                    </Form>
                                </Card.Header>
                            </Card.Content>
                        </RoundedCard>
                    )}
                    {items.map((item) => {
                        let style = {
                            wordWrap: "break-word",
                        };
                        if (item.status === 2) {
                            style["textDecorationLine"] = "line-through";
                        }
                        return (
                            <RoundedCard key={item.uuid} raised>
                                <Card.Content>
                                    <Card.Header>
                                        <div style={style}>
                                            {item.url && safeUrl(item.url) ? (
                                                <a href={normalizeUrl(safeUrl(item.url))} target="_blank"
                                                   rel="noopener noreferrer">{item.label}</a>
                                            ) : (
                                                item.label
                                            )}
                                        </div>
                                    </Card.Header>
                                    <Card.Description>
                                        <div style={style}>{item.content}</div>
                                    </Card.Description>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {item.due && formatDueDate(item.due) && (
                                            <Card.Meta
                                                style={{color: isDueDateTodayOrEarlier(item.due) ? 'red' : 'inherit'}}>
                                                {formatDueDate(item.due)}
                                            </Card.Meta>
                                        )}
                                        <Card.Meta textAlign="right">
                                            <Icon
                                                onClick={() => this.updateTaskStatus(item.uuid, item.status === 1 ? 2 : 1)}
                                            >
                                                <img 
                                                    width={16} height={16}
                                                    src={item.status === 1 ? "/circle.svg" : "/check-circle.svg"} 
                                                    alt={item.status === 1 ? "Unchecked" : "Checked"} 
                                                />
                                            </Icon>
                                            {canEditChildTask && (
                                                <Icon>
                                                    <img width={16} height={16} src="/pen-circle.svg" alt="Edit" onClick={() => this.handleEditClick(item)} />
                                                </Icon>
                                            )}
                                            {canSplitChildTask && (
                                                <Icon>
                                                    <img width={16} height={16} src="/edit-wand.svg" alt="AIssistant" onClick={() => this.aiTask(item.uuid)} />
                                                </Icon>
                                            )}
                                        </Card.Meta>
                                    </div>
                                </Card.Content>
                            </RoundedCard>
                        );
                    })}
                </Card.Group>
            </div>
        );
    }
  
    renderCompactView() {
        const { items, parentTask } = this.state;
        const canEditChildTask = this.hasPermission('edit_child_task');
        const canSplitChildTask = this.hasPermission('ai_split_child_task');

        return (
            <CompactList>
                {parentTask && canEditChildTask && (
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        const inputValue = e.target[0].value.trim();
                        if(inputValue) {                                        
                            this.addChildTask(parentTask.uuid, inputValue);
                            e.target[0].value = '';
                        }
                    }}>
                        <CompactInput
                            type="text"
                            name="task"
                            fluid
                            placeholder="Create Task"
                        />
                    </Form>
                )}
                {items.map(item => (
                    <List.Item key={item.uuid}>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <CompactCheckbox 
                                label={item.label}
                                onChange={() => this.updateTaskStatus(item.uuid, item.status === 1 ? 2 : 1)}
                                checked={item.status === 2}
                            />
                            {item.due && formatDueDate(item.due) && (
                                <span className="due-date" style={{
                                    color: isDueDateTodayOrEarlier(item.due) ? 'red' : 'inherit'
                                }}>
                                    {formatDueDate(item.due)}
                                </span>
                            )}
                            <IconContainer>
                                {canEditChildTask && (
                                    <Icon>
                                        <img width={16} height={16} src="/pen-circle.svg" alt="Edit" onClick={() => this.handleEditClick(item)} />
                                    </Icon>
                                )}
                                {canSplitChildTask && (
                                    <Icon>
                                        <img width={16} height={16} src="/edit-wand.svg" alt="AI Split" onClick={() => this.aiTask(item.uuid)} />
                                    </Icon>
                                )}
                            </IconContainer>
                        </div>
                    </List.Item>
                ))}
            </CompactList>
        );
    }
    
    render() {
        const { editTask, formData, parentTask, items } = this.state;
        const { isCompactMode } = this.props;

        const todayLame = new Date();
        const adjustedDate = new Date(todayLame.getTime() - (todayLame.getTimezoneOffset() * 60000));
        const todayGood = adjustedDate.toISOString().split('T')[0];

        return (
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr'}}>
                {parentTask ? (
                    <Header as="h2" style={{ margin: '5px 0px 0px 0px', lineHeight: '1.2' }}>
                        {parentTask.label}
                    </Header>
                ) : (
                    <Header as="h2" style={{ margin: '5px 0px 0px 0px', lineHeight: '1.2' }}>
                        No task selected
                    </Header>
                )}
                {editTask ? (
                    <div className="edit-form"
                         style={{padding: '10px', width: '100%', display: 'flex', justifyContent: 'flex-start'}}>
                        <Form onSubmit={this.handleFormSubmit} style={{ minWidth: '100%', maxWidth: '400px', margin: '0 auto'}}>
                            <Form.Field>
                                <label>Label</label>
                                <Input
                                    name="label"
                                    value={formData.label}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Content</label>
                                <Input
                                    name="content"
                                    value={formData.content}
                                    onChange={this.handleFormChange}
                                    maxLength={250} // Set maximum length attribute
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>URL</label>
                                <Input
                                    name="url"
                                    value={formData.url}
                                    onChange={this.handleFormChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Due Date</label>
                                <Input
                                    type="date"
                                    name="due"
                                    value={formData.due}
                                    onChange={this.handleFormChange}
                                    min={todayGood}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Size</label>
                                <Form.Select
                                    name="size"
                                    options={sizeOptions}
                                    value={formData.size}
                                    onChange={this.handleFormChange}
                                    placeholder="Select size"
                                />
                            </Form.Field>
                            <Button type="submit">OK</Button>
                            <Button onClick={() => this.setState({editTask: null})}>Cancel</Button>
                        </Form>
                        </div>
                ) : (
                    <>
                        {isCompactMode ? this.renderCompactView() : this.renderDetailView()}
                        {items.length === 0 && <p>No child tasks yet.</p>}
                    </>
                )}
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => {
    const diditList = React.useRef();
    
    React.useImperativeHandle(ref, () => ({
        getTask: (parentId) => {
            if (diditList.current) {
                diditList.current.getTask(parentId);
            }
        }
    }));

    return <DiditList {...props} ref={diditList} />;
});