import React, { useState, useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Loader,
    Modal,
    Header,
} from 'semantic-ui-react';
import axios from 'axios';
import AppHeader from './AppHeader';
import DiditList from './DiditList';
import OwnedDiditList from './OwnedDiditList';

import { endpoint } from './config';

function AppContent() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [authChecked, setAuthChecked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const diditListRef = useRef();
    const [isCompactMode, setIsCompactMode] = useState(true);
    const [currentView, setCurrentView] = useState('main');
    const [oauthResponse, setOauthResponse] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleAuthCallback = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            if (code) {
                console.log("OAuth code received:", code);
                setOauthResponse(code);
                window.history.replaceState({}, document.title, window.location.pathname);
            } else {
                console.log("No OAuth code in URL");
            }
        };

        handleAuthCallback();
    }, []);

    useEffect(() => {
        const checkAuth = async () => {
            console.log("Checking authentication...");
            try {
                setLoading(true);
                setError(null);
                const response = await fetch(endpoint + '/api/user', {
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                });
                console.log("Auth check response status:", response.status);
                if (response.ok) {
                    const userData = await response.json();
                    console.log("User data received:", userData);
                    setUser(userData);
                } else if (response.status === 401) {
                    console.log("User not authenticated");
                    setUser(null);
                } else {
                    throw new Error('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error checking authentication:', error);
                setError(error.message);
            } finally {
                setLoading(false);
                setAuthChecked(true);
            }
        };

        if (oauthResponse || !authChecked) {
            checkAuth();
        } else {
            console.log("Auth check not triggered");
            setLoading(false);
        }
    }, [oauthResponse, authChecked]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const parentId = params.get('p');
        if (parentId && diditListRef.current) {
            diditListRef.current.getTask(parentId);
        }
    }, [location]);

    const handleLogin = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const parentUUID = queryParams.get('p') || queryParams.get('parent');
        window.location.href = `${endpoint}/auth/google/login?parent=${parentUUID}`;
    };

    const handleLogout = async () => {
        try {
            const response = await fetch(endpoint + '/auth/logout', {
                method: 'POST',
                credentials: 'include',
            });
            if (response.ok) {
                setUser(null);
                setAuthChecked(prev => !prev);
                setModalContent({
                    header: "Logged Out",
                    message: "You have been successfully logged out of this application. You are still connected to Google."
                });
                setModalOpen(true);
            } else {
                throw new Error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
            setError(error.message);
        }
    };

    const handleDisconnect = async () => {
        try {
            const response = await fetch(endpoint + '/auth/disconnect', {
                method: 'POST',
                credentials: 'include',
            });
            if (response.ok) {
                setUser(null);
                setAuthChecked(prev => !prev);
                setModalContent({
                    header: "Disconnected from Google",
                    message: "Your account has been successfully disconnected from Google. You will need to re-authorize this application to use it again."
                });
                setModalOpen(true);
            } else {
                throw new Error('Disconnect failed');
            }
        } catch (error) {
            console.error('Error during disconnect:', error);
            setError(error.message);
        }
    };

    const InfoModal = () => (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            size="small"
        >
            <Header icon="info circle" content={modalContent.header} />
            <Modal.Content>
                <p>{modalContent.message}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='green' onClick={() => setModalOpen(false)}>
                    Understood
                </Button>
            </Modal.Actions>
        </Modal>
    );

    const getUserDisplayName = (user) => {
        return user.Name || user.Email || 'User';
    };

    const addTasks = async (tasks) => {
        const queryParams = new URLSearchParams(window.location.search);
        let parentUUID = queryParams.get('p') || queryParams.get('parent');
    
        if (!parentUUID) {
            try {
                const res = await axios.post(
                    `${endpoint}/api/createParentTask`,
                    { label: "ParentTask" },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                console.log(res);
                parentUUID = res.data.uuid;
            } catch (error) {
                console.error("Error creating parent task:", error);
                return; // Exit the function if we can't create a parent task
            }
        }
    
        for (const task of tasks) {
            try {
                const response = await axios.post(
                    `${endpoint}/api/createChildTask`,
                    {
                        parent: parentUUID,
                        label: task.label,
                        content: task.content
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                console.log(`Task added: ${task.label}`);
                // You might want to do something with the response, like updating the UI
            } catch (error) {
                console.error(`Error adding task ${task.label}:`, error);
                // You might want to show an error message to the user here
            }
        }
    
        const newTaskUrl = `?p=${parentUUID}`;
        window.history.pushState({}, '', newTaskUrl);
    
        if (diditListRef.current) {
            diditListRef.current.getTask();
        }
    };
    
    const toggleViewMode = () => {
        setIsCompactMode(prevMode => !prevMode);
    };

    const handleTaskClick = (task) => {
        setCurrentView('main');
        navigate(`/?p=${task.uuid}`);
        if (diditListRef.current) {
            diditListRef.current.getTask(task.uuid);
        }
    };

    if (loading) {
        return (
            <Container text style={{ marginTop: '7em' }}>
                <Loader active>Loading</Loader>
            </Container>
        );
    }

    return (
        <div>
            <AppHeader
                user={user}
                addTasks={addTasks}
                diditListRef={diditListRef}
                getUserDisplayName={getUserDisplayName}
                handleLogout={handleLogout}
                handleLogin={handleLogin}
                isCompactMode={isCompactMode}
                toggleViewMode={toggleViewMode}
                setCurrentView={setCurrentView}
            />
            <Container text style={{ marginTop: '4em' }}>
                <Routes>
                    <Route path="/" element={
                        <DiditList
                            user={user}
                            ref={diditListRef}
                            isCompactMode={isCompactMode}
                        />
                    } />
                    <Route path="/owned" element={
                        <OwnedDiditList
                            isCompactMode={isCompactMode}
                            onTaskClick={handleTaskClick}
                        />
                    } />
                </Routes>
            </Container>
            <InfoModal />
        </div>
    );
}

export default AppContent;