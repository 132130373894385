// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.logo {
    flex: 1 0 auto; /* Allow the logo to grow and shrink */
    padding: 5px;
}

.edit-form {
    flex: 3 0 70%;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: flex-start; /* Align items to the start (left) of the flex container */
}

.card-group {
    flex: 3 0 70%; /* Allow the card group to grow and shrink, and take up to 70% width */
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
}

.card {
    margin: 10px 0;
    border-radius: 20px;
}

.icon-with-padding {
    padding: 0px 15px; /* Adjust the padding value as needed */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .logo,
    .card-group {
        flex: 1 0 100%; /* Both the logo and card group take full width on small screens */
    }

    .card {
        flex: 1 0 100%; /* Cards still take 100% width */
    }
}
`, "",{"version":3,"sources":["webpack://./src/DiditList.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,cAAc,EAAE,sCAAsC;IACtD,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,eAAe;IACf,aAAa;IACb,2BAA2B,EAAE,0DAA0D;AAC3F;;AAEA;IACI,aAAa,EAAE,sEAAsE;IACrF,aAAa;IACb,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,iBAAiB,EAAE,uCAAuC;AAC9D;;AAEA,oCAAoC;AACpC;IACI;;QAEI,cAAc,EAAE,kEAAkE;IACtF;;IAEA;QACI,cAAc,EAAE,gCAAgC;IACpD;AACJ","sourcesContent":["/* styles.css */\n\n.container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.logo {\n    flex: 1 0 auto; /* Allow the logo to grow and shrink */\n    padding: 5px;\n}\n\n.edit-form {\n    flex: 3 0 70%;\n    display: flex;\n    flex-wrap: wrap;\n    padding: 10px;\n    justify-content: flex-start; /* Align items to the start (left) of the flex container */\n}\n\n.card-group {\n    flex: 3 0 70%; /* Allow the card group to grow and shrink, and take up to 70% width */\n    display: flex;\n    flex-wrap: wrap;\n    padding: 0px;\n}\n\n.card {\n    margin: 10px 0;\n    border-radius: 20px;\n}\n\n.icon-with-padding {\n    padding: 0px 15px; /* Adjust the padding value as needed */\n}\n\n/* Media query for smaller screens */\n@media (max-width: 768px) {\n    .logo,\n    .card-group {\n        flex: 1 0 100%; /* Both the logo and card group take full width on small screens */\n    }\n\n    .card {\n        flex: 1 0 100%; /* Cards still take 100% width */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
