import React, { useState, useEffect } from 'react';
import { List, Card, Button, Icon, Header } from 'semantic-ui-react';
import axios from 'axios';
import { endpoint } from './config';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const RoundedCard = styled(Card)`
  &&& {
    border-radius: 20px !important;
    overflow: hidden; // This ensures the content inside the card respects the border radius
  }
`;

const CompactList = styled(List)`
  .item {
    padding: 0px 0px 0px 0px;
    margin-bottom: 8px;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
    transition: box-shadow 0.2s;

    > div {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      padding: 8px 10px;
    }

    .task-label {
      flex: 1;
      min-width: 0; // This allows the flex item to shrink below its content size
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .due-date {
      white-space: nowrap;
      margin-left: 10px;
      margin-right: 5px;
      font-size: 0.9em;
    }
      
    &:hover {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

  }
`;

const OwnedDiditList = ({ isCompactMode, onTaskClick }) => {
    const [tasks, setTasks] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchOwnedTasks();
    }, []);

    const fetchOwnedTasks = async () => {
        try {
            const response = await axios.post(`${endpoint}/api/getOwnedTasks`);
            setTasks(response.data);
        } catch (error) {
            console.error('Error fetching owned tasks:', error);
        }
    };

    const handleTaskClick = (task) => {
        navigate(`/?p=${task.uuid}`);
        onTaskClick(task);
    };

    const renderCompactView = () => (
        <CompactList>
            {tasks.map(task => (
                <List.Item key={task.uuid} onClick={() => handleTaskClick(task)}>
                    <div>
                        <span className="task-label">{task.label}</span>
                        {task.created && (
                            <span className="due-date">{new Date(task.created).toLocaleDateString()}</span>
                        )}
                        {task.due > 0 && (
                            <span className="due-date">{new Date(task.due).toLocaleDateString()}</span>
                        )}
                    </div>
                </List.Item>
            ))}
        </CompactList>
    );

    const renderDetailView = () => (
        <Card.Group stackable doubling>
            {tasks.map(task => (
                <RoundedCard key={task.uuid} onClick={() => handleTaskClick(task)}>
                    <Card.Content>
                        <Card.Header>{task.label}</Card.Header>
                        <Card.Description>{task.content}</Card.Description>
                        {task.created && (
                            <Card.Meta>Created: {new Date(task.created).toLocaleDateString()}</Card.Meta>
                        )}
                        {task.due > 0 && (
                            <Card.Meta>Due: {new Date(task.due).toLocaleDateString()}</Card.Meta>
                        )}
                    </Card.Content>
                </RoundedCard>
            ))}
        </Card.Group>
    );

    return (
        <div>
            <Header as='h2'>
                <Icon style={{ marginRight: '5px' }}>
                    <img src="../ownership.svg" style={{width: '32px', height: '32px'}} />
                </Icon>
                <Header.Content>Owned Didits</Header.Content>
            </Header>
            {tasks.length > 0 ? (
                isCompactMode ? renderCompactView() : renderDetailView()
            ) : (
                <p>No tasks currently owned</p>
            )}
        </div>
    );
};
  
export default OwnedDiditList;