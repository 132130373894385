import React, { useState, useRef, useEffect } from 'react';
import { Menu, Icon, Image, Dropdown, Button, Modal, Form, TextArea, Grid, Message, Input } from 'semantic-ui-react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

import { endpoint } from './config';

const menuItemStyle = {
    padding: '0.5em 0.7em',  // Reduce padding
    border: 'none !important',
    boxShadow: 'none !important',
    borderRadius: 0,
    margin: 0
};

// Styled components to override Semantic UI styles
const StyledMenu = styled(Menu)`
  &&& {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
    min-height: auto !important;
    height: 50px; // Set a fixed height for the menu
  }

  &&& .item {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 0.7em !important;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;

    &:before {
      display: none !important;
    }
  }
`;

const CenteredIcon = styled(Icon)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
`;


const StyledQRCodeModal = styled(Modal)`
  &&& {
    width: auto !important;
    max-width: 90vw;
  }
`;

const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const CloseButton = styled(Button)`
  &&& {
    margin-top: 20px;
  }
`;

const AppHeader = ({
    user,
    handleLogin,
    handleLogout,
    handleUserSettings,
    handleAssignedDidits,
    handleDelegates,
    getUserDisplayName,
    addTasks,
    diditListRef,
    refreshParentComponent,
    isCompactMode,
    toggleViewMode, 
    setCurrentView
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [taskDescription, setTaskDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [generatedTasks, setGeneratedTasks] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageThumbnail, setImageThumbnail] = useState(null);
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);
    const cameraInputRef = useRef(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);

    const navigate = useNavigate();

    const [isParentTaskModalOpen, setIsParentTaskModalOpen] = useState(false);
    const [parentTaskFormData, setParentTaskFormData] = useState({
        label: '',
        content: '',
        url: '',
        due: '',
        size: 1
    });

    const openParentTaskModal = () => {
        setParentTaskFormData({
            label: 'New didit',
            content: '',
            url: '',
            due: '',
            size: 1
        });
        setIsParentTaskModalOpen(true);
    };

    const handleParentTaskFormChange = (e, { name, value }) => {
        setParentTaskFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleParentTaskSubmit = () => {
        const updatedTask = {
            ...parentTaskFormData,
            due: parentTaskFormData.due ? new Date(parentTaskFormData.due).toISOString() : null,
        };
    
        axios.post(
            `${endpoint}/api/createParentTask`,
            updatedTask,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => {
            console.log(res);
            const newParent = res.data.uuid;
            console.log("newParent", newParent);
            
            // Navigate to the new task view
            navigate(`/?p=${newParent}`);
    
            // Set the current view to 'main'
            setCurrentView('main');

            // Close the modal
            setIsParentTaskModalOpen(false);

            // Reset the form data
            setParentTaskFormData({
                label: 'New didit',
                content: '',
                url: '',
                due: '',
                size: 1
            });

            // Use setTimeout to ensure navigation has completed before updating DiditList
            setTimeout(() => {
                if (diditListRef.current) {
                    diditListRef.current.getTask(newParent);
                }
            }, 100);
        })
        .catch((error) => {
            console.error("Error creating parent task:", error);
            // Handle error (e.g., show an error message to the user)
        });
    };
        
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setTaskDescription('');
        setGeneratedTasks(null);
    };

    const resizeImage = (file, maxDimension = 512) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = document.createElement('img');
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxDimension) {
                            height = Math.round((height * maxDimension) / width);
                            width = maxDimension;
                        }
                    } else {
                        if (height > maxDimension) {
                            width = Math.round((width * maxDimension) / height);
                            height = maxDimension;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
                        } else {
                            reject(new Error('Failed to create blob from canvas'));
                        }
                    }, 'image/jpeg', 0.85);
                };
                img.onerror = () => {
                    reject(new Error('Failed to load image'));
                };
                img.src = e.target.result;
            };
            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };
            reader.readAsDataURL(file);
        });
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                console.log('Original file:', file.name, file.type, file.size);
                const resizedFile = await resizeImage(file);
                console.log('Resized file:', resizedFile.name, resizedFile.type, resizedFile.size);
                setSelectedImage(resizedFile);
                const thumbnail = URL.createObjectURL(resizedFile);
                setImageThumbnail(thumbnail);
                setError(null); // Clear any previous errors
            } catch (error) {
                console.error('Error resizing image:', error);
                setError(`Failed to process the image: ${error.message}`);
                setSelectedImage(null);
                setImageThumbnail(null);
            }
        }
    };

    const handleTakePicture = () => {
        cameraInputRef.current.click();
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setImageThumbnail(null);
        setError(null); // Clear any errors when removing the image
    };

    const handleGenerateTasks = async () => {
        if (!taskDescription.trim() && !selectedImage) {
            setError("Please provide a task description or upload an image.");
            return;
        }
        setIsLoading(true);
        setError(null);
        try {
            const formData = new FormData();
            formData.append('description', taskDescription);
            if (selectedImage) {
                formData.append('image', selectedImage);
            }

            const response = await axios.post(`${endpoint}/api/generateTask`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setGeneratedTasks(response.data);
        } catch (error) {
            console.error('Error generating tasks:', error);
            setError(error.response?.data || error.message || "An unexpected error occurred.");
        } finally {
            setIsLoading(false);
        }
    };

    const renderModalContent = () => {
        if (generatedTasks) {
            return (
                <div>
                    <h4>Generated Tasks:</h4>
                    <ul>
                        {generatedTasks.map((task, index) => (
                            <li key={index}>{task.label}</li>
                        ))}
                    </ul>
                </div>
            );
        }

        return (
            <Grid columns={imageThumbnail ? 2 : 1} divided>
                <Grid.Row>
                    <Grid.Column width={imageThumbnail ? 10 : 16}>
                        <Form onSubmit={handleFormSubmit} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Form.Field style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <label>Task List Description</label>
                                <TextArea
                                    placeholder="Describe the task list you want to generate..."
                                    value={taskDescription}
                                    onChange={(e) => setTaskDescription(e.target.value)}
                                    disabled={isLoading}
                                    style={{ flexGrow: 1, minHeight: '100px', resize: 'none' }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Button.Group>
                                    <Button as="label" htmlFor="file" icon type="button">
                                        <Icon name="file image outline" />
                                        Upload Image
                                    </Button>
                                    <Button icon onClick={handleTakePicture} type="button">
                                        <Icon name="camera" />
                                        Take Picture
                                    </Button>
                                </Button.Group>
                                <input
                                    type="file"
                                    id="file"
                                    hidden
                                    onChange={handleImageUpload}
                                    accept="image/*"
                                    ref={fileInputRef}
                                />
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleImageUpload}
                                    accept="image/*"
                                    capture="environment"
                                    ref={cameraInputRef}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                    {imageThumbnail && (
                        <Grid.Column width={6}>
                            <Image src={imageThumbnail} size="medium" />
                            <Button icon labelPosition="left" onClick={handleRemoveImage} style={{ marginTop: '1em' }}>
                                <Icon name="remove" />
                                Remove Image
                            </Button>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        );
    };

    const handleAddTasks = async () => {
        if (generatedTasks) {
            setIsLoading(true);
            try {
                await addTasks(generatedTasks);
                handleCloseModal();
            } catch (error) {
                console.error('Error adding tasks:', error);
                // You might want to show an error message to the user here
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!generatedTasks) {
            handleGenerateTasks();
        }
    };

    const handleOwnedDidits = () => {
        setCurrentView('owned');
        navigate('/owned');
    };

    const hasPermission = (permission) => {
        return user && user.Permissions && user.Permissions.includes(permission);
    };

    const handleShareParentTask = () => {
        const currentUrl = window.location.href;
        let taskTitle = 'Task';

        try {
            if (diditListRef.current && diditListRef.current.state && diditListRef.current.state.parentTask) {
                taskTitle = diditListRef.current.state.parentTask.label || 'Task';
            }
        } catch (error) {
            console.error('Error accessing parent task:', error);
        }
        
        if (navigator.share) {
            navigator.share({
                title: taskTitle,
                text: `Check out this task: ${taskTitle}`,
                url: currentUrl,
            })
            .then(() => console.log('Successful share'))
            .catch((error) => {
                console.log('Error sharing', error);
                setIsShareModalOpen(true);
            });
        } else {
            setIsShareModalOpen(true);
        }
    };

    const handleShareQRCode = () => {
        setIsQRCodeModalOpen(true);
    };

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            setCopySuccess(successful ? 'Copied!' : 'Copy failed');
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            setCopySuccess('Copy failed');
        }

        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = (text) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function() {
                setCopySuccess('Copied!');
            },
            function(err) {
                console.error('Async: Could not copy text: ', err);
                setCopySuccess('Copy failed');
            }
        );
    };

    const handleCopyLink = () => {
        const currentUrl = window.location.href;
        copyTextToClipboard(currentUrl);
    };


    return (
        <>
            <StyledMenu fixed="top">
                <Menu.Item as="a" header style={{
                    ...menuItemStyle,
                    padding: '0.5em',
                    paddingRight: '.5em',
                    paddingLeft: '.75em'
                }}>
                    <img
                        src="didit_logo_bold.svg"
                        alt="didit"
                        height={32}
                        style={{
                            width: 'auto',
                            maxHeight: '100%'
                        }}
                    />
                </Menu.Item>
                <Menu.Item as="a" header style={menuItemStyle} onClick={toggleViewMode}>
                    <CenteredIcon>
                        <img 
                            width={24} 
                            height={24} 
                            src={isCompactMode ? "/detail_view.svg" : "/compact_view.svg"} 
                            alt={isCompactMode ? "Switch to Detail View" : "Switch to Compact View"} 
                        />
                    </CenteredIcon>
                </Menu.Item>
                {hasPermission('create_parent_task') && (
                    <Menu.Item as="a" header style={menuItemStyle}>
                        <CenteredIcon>
                            <img width={24} height={24} src="/layer-plus.svg" alt="New Didit" onClick={openParentTaskModal} />
                        </CenteredIcon>                        
                    </Menu.Item>
                )}
                {hasPermission('ai_add_child_tasks') && (
                    <Menu.Item as="a" header style={menuItemStyle} onClick={handleOpenModal}>
                        <CenteredIcon>
                            <img width={24} height={24} src="ai_icon_outline.svg" alt="AI Icon"/>
                        </CenteredIcon>                        
                    </Menu.Item>
                )}
                
                <Menu.Item as="a" header style={menuItemStyle} onClick={handleShareParentTask}>
                    <CenteredIcon>
                        <img width={24} height={24} src="share-alt-square.svg" alt="Share Task"/>
                    </CenteredIcon>                        
                </Menu.Item>
                <Menu.Item as="a" header style={menuItemStyle} onClick={handleShareQRCode}>
                    <CenteredIcon>
                        <img width={24} height={24} src="qr.svg" alt="QR Code"/>
                    </CenteredIcon>                        
                </Menu.Item>
                
                <Menu.Item position="right" style={menuItemStyle}>
                {user ? (
                    <>
                        <Dropdown
                            trigger={
                                <span>
                                    {user.Picture && <Image src={user.Picture} avatar />}
                                </span>
                            }
                            pointing="top right"
                            icon={null}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                        {getUserDisplayName(user)}
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleOwnedDidits}>
                                    <Icon>
                                        <img src="ownership.svg" alt="Owned" style={{width: '14px', height: '14px', marginRight: '8px'}} />
                                    </Icon>
                                    Owned
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleAssignedDidits}>
                                        <Icon>
                                            <img src="responsibility.svg" alt="Logout" style={{width: '14px', height: '14px', marginRight: '8px'}} />
                                        </Icon>
                                        Assigned
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleDelegates}>
                                        <Icon>
                                            <img src="users-alt.svg" alt="Logout" style={{width: '14px', height: '14px', marginRight: '8px'}} />
                                        </Icon>
                                        Delegates
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleUserSettings}>
                                        <Icon>
                                            <img src="customization-cogwheel.svg" alt="Logout" style={{width: '14px', height: '14px', marginRight: '8px'}} />
                                        </Icon>
                                        Settings
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>
                                        <Icon>
                                            <img src="sign-out-alt.svg" alt="Logout" style={{width: '14px', height: '14px', marginRight: '8px'}} />
                                        </Icon>
                                        Logout
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                ) : (
                    <CenteredIcon onClick={handleLogin} style={{cursor: 'pointer'}}>
                        <img src="circle-user-unknown.svg" alt="Login" style={{width: '32px', height: '32px'}} />
                    </CenteredIcon>
                )}
                </Menu.Item>
            </StyledMenu>
            {/* Parent Task Modal */}
            <Modal open={isParentTaskModalOpen} onClose={() => setIsParentTaskModalOpen(false)}>
                <Modal.Header>Create Parent Task</Modal.Header>
                <Modal.Content>
                    <Form onSubmit={handleParentTaskSubmit}>
                        <Form.Field>
                            <label>Label</label>
                            <Form.Input
                                name="label"
                                value={parentTaskFormData.label}
                                onChange={handleParentTaskFormChange}
                                placeholder="New didit"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Content</label>
                            <Form.TextArea
                                name="content"
                                value={parentTaskFormData.content}
                                onChange={handleParentTaskFormChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>URL</label>
                            <Form.Input
                                name="url"
                                value={parentTaskFormData.url}
                                onChange={handleParentTaskFormChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Due Date</label>
                            <Form.Input
                                type="date"
                                name="due"
                                value={parentTaskFormData.due}
                                onChange={handleParentTaskFormChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Size</label>
                            <Form.Select
                                name="size"
                                options={[
                                    { key: '1', text: 'Small', value: 1 },
                                    { key: '2', text: 'Medium', value: 2 },
                                    { key: '3', text: 'Large', value: 3 }
                                ]}
                                value={parentTaskFormData.size}
                                onChange={handleParentTaskFormChange}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsParentTaskModalOpen(false)}>Cancel</Button>
                    <Button positive onClick={handleParentTaskSubmit}>Create</Button>
                </Modal.Actions>
            </Modal>
            <Modal open={isModalOpen} onClose={handleCloseModal} size="small">
                <Modal.Header>Generate Task List</Modal.Header>
                <Modal.Content>
                    {error && (
                        <Message negative>
                            <Message.Header>Error</Message.Header>
                            <p>{error}</p>
                        </Message>
                    )}
                    {renderModalContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    {!generatedTasks ? (
                        <Button
                            primary
                            onClick={handleGenerateTasks}
                            loading={isLoading}
                            disabled={(!taskDescription.trim() && !selectedImage) || isLoading}
                        >
                            Generate Tasks
                        </Button>
                    ) : (
                        <Button primary onClick={handleAddTasks} loading={isLoading}>
                            Add Tasks
                        </Button>
                    )}
                </Modal.Actions>
            </Modal>
            {/* Share Modal */}
            <Modal open={isShareModalOpen} onClose={() => setIsShareModalOpen(false)}>
                <Modal.Header>Share Task</Modal.Header>
                <Modal.Content>
                    <p>Share this task via:</p>
                    <Button as="a" href={`mailto:?subject=Check out this task&body=${encodeURIComponent(window.location.href)}`}>
                        Email
                    </Button>
                    <Button as="a" href={`sms:?body=${encodeURIComponent(window.location.href)}`}>
                        SMS
                    </Button>
                    <Button onClick={handleCopyLink}>
                        Copy Link
                    </Button>
                    {copySuccess && <p>{copySuccess}</p>}
                </Modal.Content>
            </Modal>
            {/* QR Code Modal */}
            <StyledQRCodeModal 
                open={isQRCodeModalOpen} 
                onClose={() => setIsQRCodeModalOpen(false)}
                size="mini"
            >
                <Modal.Content>
                <QRCodeContainer>
                    <QRCode value={window.location.href} size={256} />
                    <CloseButton onClick={() => setIsQRCodeModalOpen(false)}>
                    Close
                    </CloseButton>
                </QRCodeContainer>
                </Modal.Content>
            </StyledQRCodeModal>
        </>
    );
};

export default AppHeader;